import React, {useState} from 'react';
import { Doughnut, Bar, PolarArea } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  RadialLinearScale
} from 'chart.js';
import Grid from '@mui/material/Grid';
// import styles from '../App.css';
import { defaultTheme } from "../../../styles/Theme";
import { DELIVERY_PARTNERS_COLOR, COUNTRY_COLOR_CODES } from "../../../utils/Constants"
import {useDbData} from "../hooks/useDbData"
import { RPS_DELIVERY_PARTNER_NAMES } from '../util';

const styles = {
  containerStyle: {
    marginBottom: '2%'
  },
  chartStyle: {
    backgroundColor: defaultTheme.palette.primary.contrastText
  }
}

export function Report({ allTimeDeliveriesStatusesReport, allTimeDeliveredByPartner, ordersByMonthCountry, ordersByMonthPartner, averageOrderCosts}) {

  const bgColors = ['rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(201, 203, 207, 0.2)',
    `rgba(204, 255, 255, 1)`,
    `rgba(255, 204, 255, 1)`];

  const bdColors = ['rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(75, 192, 192)',
    'rgb(255, 159, 64)',
    'rgb(153, 102, 255)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    `rgba(51, 255, 255, 1)`,
    `rgba(255, 51, 153, 1)`]

  ChartJS.register(ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    RadialLinearScale);

  const doughnutOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Order Status'
      }
    }
  };

  const [doughnutChartData, setDoughnutChartData] = useState({
    labels: ["Created", "On Going", "Picked-up", "Delivered", "Completed", "Return Initiated", "Cancelled", "Expired", "Failed"],
    datasets: [
      {
        label: '# of Votes',
        data: 
        [allTimeDeliveriesStatusesReport.created,
        allTimeDeliveriesStatusesReport["pickup-underway"],
        allTimeDeliveriesStatusesReport.picked,
        allTimeDeliveriesStatusesReport.delivered,
        allTimeDeliveriesStatusesReport.completed,
        allTimeDeliveriesStatusesReport["return-underway"],
        allTimeDeliveriesStatusesReport.cancelled,
        allTimeDeliveriesStatusesReport.expired,
        allTimeDeliveriesStatusesReport.failed],
        backgroundColor: bgColors,
        borderColor: bdColors,
        borderWidth: 1,
      },
    ],
  })

  const { getAllTimeDeliveriesStatusesReport } = useDbData();

  const handleDropdownChange = async (deliveryPartnerName) => {
    
    const updatedData = await getAllTimeDeliveriesStatusesReport(deliveryPartnerName);
    
    const newData = {
      ...doughnutChartData,
      datasets: [
        {
          backgroundColor: bgColors,
          borderColor: bdColors,
          data: [updatedData.created,
          updatedData["pickup-underway"],
          updatedData.picked,
          updatedData.delivered,
          updatedData.completed,
          updatedData["return-underway"],
          updatedData.cancelled,
          updatedData.expired,
          updatedData.failed],
        },
      ],
    };

    setDoughnutChartData(newData);
  };

  const polarAreaOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Order fulfillment by Delivery Partner'
      }
    }
  };

  let polarAreaLabels = [];
  let polarAreaDataSet = [];
  let polarBackgroundColors = [];
  for (let i in allTimeDeliveredByPartner) {
    polarAreaLabels.push(allTimeDeliveredByPartner[i]._id);
    polarAreaDataSet.push(allTimeDeliveredByPartner[i].count);
    polarBackgroundColors.push(allTimeDeliveredByPartner[i].bgcolor)
  }

  const polarAreaData = {
    labels: polarAreaLabels,
    datasets: [
      {
        label: 'Order fulfillment by Delivery Partner',
        data: polarAreaDataSet,
        backgroundColor: polarBackgroundColors,
        borderWidth: 1,
      },
    ],
  };

  let ordersByMonthCountryDataSet = [];
  let ordersByMonthCountryLabels = [];

  if (ordersByMonthCountry) {    

    ordersByMonthCountryLabels = ordersByMonthCountry.monthLables;
    ordersByMonthCountry = ordersByMonthCountry.data

    for (let i in ordersByMonthCountry) {
      let curr = ordersByMonthCountry[i];

      let monthData = [];
      for (let j in curr.monthData) {
        monthData.push(curr.monthData[j].count);
      }

      ordersByMonthCountryDataSet.push({
        label: curr._id.country,
        backgroundColor: COUNTRY_COLOR_CODES[curr._id.country],
        data: monthData
      });
    }
  }

  let ordersByMonthCountryData = {
    labels: ordersByMonthCountryLabels,
    datasets: ordersByMonthCountryDataSet
  };

  let ordersByMonthCountryChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Order volume by Country',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    }
  }

  let ordersByMonthPartnerDataSet = [];
  let ordersByMonthPartnerLabels = [];

  if (ordersByMonthPartner) {
    
    ordersByMonthPartnerLabels = ordersByMonthPartner.monthLables;
    ordersByMonthPartner = ordersByMonthPartner.data

    for (let i in ordersByMonthPartner) {
      let curr = ordersByMonthPartner[i];

      let monthData = [];
      for (let j in curr.monthData) {
        monthData.push(curr.monthData[j].count);
      }

      ordersByMonthPartnerDataSet.push({
        label: curr._id.deliveryPartner,
        backgroundColor: curr._id.bgcolor,
        data: monthData
      });
    }
  }

  let ordersByMonthPartnerData = {
    labels: ordersByMonthPartnerLabels,
    datasets: ordersByMonthPartnerDataSet
  };

  let ordersByMonthPartnerChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Order volume by Delivery Partner',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    }
  }

  let averageOrderCostsDataSet = [];
  let averageOrderCostsLabels = [];

  if (averageOrderCosts) {
    
    averageOrderCostsLabels = averageOrderCosts.monthLables;
    averageOrderCosts = averageOrderCosts.data

    for (let i in averageOrderCosts) {
      let curr = averageOrderCosts[i];

      let monthData = [];
      for (let j in curr.monthData) {
        monthData.push(curr.monthData[j].averageAmount);
      }

      averageOrderCostsDataSet.push({
        label: curr._id.deliveryPartner,
        backgroundColor: curr._id.bgcolor,
        data: monthData
      });
    }
  }

  let averageOrderCostsData = {
    labels: averageOrderCostsLabels,
    datasets: averageOrderCostsDataSet
  };

  let averageOrderCostsChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Average Order Cost by Delivery Partner',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    }
  }

  return <>
    <Grid container spacing={4} style={styles.containerStyle}>
      <Grid item xs={6}>
        <Bar options={ordersByMonthCountryChartOptions} data={ordersByMonthCountryData} style={styles.chartStyle} />
      </Grid>
      <Grid item xs={6}>
        <Bar options={ordersByMonthPartnerChartOptions} data={ordersByMonthPartnerData} style={styles.chartStyle} />
      </Grid>
    </Grid>
    <Grid container style={styles.containerStyle}>
      <Grid item xs={6}>
        <div>
          <select onChange={handleDropdownChange}>
            <option value="" >Select a delivery partner</option>
            <option value="lalamove">Lalamove</option>
            <option value="borzo">Borzo</option>
            <option value="rpx">RPX</option>
            <option value="ghtk">GHTK</option>
            <option value="grab express">Grab Express</option>
            <option value="consegnia">Consegnia</option>
            <option value="ahamove">Ahamove</option>
            <option value="nhat tin">Nhat tin</option>
            <option value="lbc express - corporate">LBC Express Corporate</option>
            {RPS_DELIVERY_PARTNER_NAMES.map(partner => <option value={partner}>{partner.toUpperCase()}</option>)}
          </select>
        </div>
        <Doughnut options={doughnutOptions} data={doughnutChartData} />
      </Grid>
      <Grid item xs={6}>
        <PolarArea options={polarAreaOptions} data={polarAreaData} />
      </Grid>
    </Grid>
    <Grid container style={styles.containerStyle}>
      <Grid item xs={6}>
        <Bar options={averageOrderCostsChartOptions} data={averageOrderCostsData} style={styles.chartStyle} />
      </Grid>
    </Grid>
    <Grid item xs={12} style={styles.containerStyle}>
      
    </Grid>
  </>
}

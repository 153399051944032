import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { defaultTheme } from '../../styles/Theme';
import { getTrackingSteps } from '../../services/ReportService';

const styles = {
    dropdownStyle: {
        fontWeight: 'bold',
        marginBottom: '2%',
        marginTop: '2%'
    },
    containerStyle: {
        marginTop: '0.1%',
        marginBottom: '2%',
        backgroundColor: defaultTheme.palette.primary.contrastText
    },
    stepperStyle: {
        marginBottom: '6%'
    },
    frameStyle: {
        border: "none",
        height: "40em",
        width: "100%"
    }
}

const Track = () => {
    const [trackingTitle, setTrackingTitle] = useState("");
    const [trackingUrl, setTrackingUrl] = useState("");
    const [steps, setSteps] = useState([]);
    const [displayStatus, setDisplayStatus] = useState(false);
    const [activeSteps, setActiveSteps] = useState(0);
    const [language, setLanguage] = useState('');

    async function languageChange(language) {
        let params = await new URLSearchParams(window.location.search);
        let logisticsId = await params.get('id');
        const trackingResponse = await getTrackingSteps(logisticsId, language)

        if (trackingResponse.code === 2000) {
            if (trackingResponse.result.isFulfilled) {
                setTrackingTitle(trackingResponse.result.isFulfilled);
                setSteps([]);
                setDisplayStatus(false);
                setActiveSteps(0);
            }
            else {
                setTrackingTitle(trackingResponse.result.trackingTitle);
                setTrackingUrl(trackingResponse.result.trackingUrl);
                setSteps(trackingResponse.result.statusLog);
                setDisplayStatus(true);
                setActiveSteps(trackingResponse.result.activeSteps);
            }
        }
        else {
            setTrackingTitle(trackingResponse.result.message);
            setSteps([]);
            setDisplayStatus(false);
            setActiveSteps(0);
        }
    }

    const handleChange = async (event) => {
        setLanguage(event.target.value)
        languageChange(event.target.value);
    };

    useEffect(async () => {
        languageChange("");
    }, []);

    return (<Container>
        <Typography variant="h5" style={styles.dropdownStyle}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Select value={language} onChange={handleChange} displayEmpty>
                        <MenuItem value={""}>Select Language</MenuItem>
                        <MenuItem value={"EN"}>English</MenuItem>
                        <MenuItem value={"BI"}>Bahasa Indonesia</MenuItem>
                        <MenuItem value={"MY"}>Malay</MenuItem>
                        <MenuItem value={"VN"}>Vietnamese</MenuItem>
                        <MenuItem value={"TH"}>Thai</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {trackingTitle}
                </Grid>
            </Grid>
        </Typography>

        <Container>
            <Grid container spacing={4} style={styles.containerStyle}>
                <Grid item xs={3}>

                    {displayStatus ?
                        <Stepper activeStep={activeSteps} orientation="vertical" style={styles.stepperStyle}>
                            {steps.map((step, index) => (
                                <Step active={step.completed} key={index}>
                                    <StepLabel>
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        : null}
                </Grid>
                <Grid item xs={9}>
                    <iframe
                        title="1"
                        style={styles.frameStyle}
                        src={trackingUrl}>
                    </iframe>
                </Grid>
            </Grid>
        </Container>
    </Container>
    )
}

export default Track